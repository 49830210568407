import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import "./Companies.scss";
import Container from "react-bootstrap/Container";
import PageHeader from "../PageHeader/PageHeader";
import SearchFilter from "../SearchFilter/SearchFilter";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router";
import { useCompanies } from "../../../CompaniesContext";
import Alert from "react-bootstrap/Alert";
import CompCard from "./CompCard/CompCard";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Helmet } from "react-helmet";

const useQuery = () => {
  const { search } = useLocation();
  console.log("search", search);
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
function Companies(props) {
  let query = useQuery();
  let location = useLocation();
  const [params, setParams] = useState({
    state: "",
    service: "",
  });
  const [allActiveCompanies, setAllActiveCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState({ error: false, message: "" });
  useEffect(() => {
    // if (allActiveCompanies.length < 1) {
    //   //* gets all active companies
    //   searchCompanies('','',true);
    // }
    let state = query.get('state')
    let service = query.get('service')
    searchCompanies(state,service,false)
  }, [location.search]);
  const getAllCompanies = () => {
    if(allActiveCompanies.length < 1){

      searchCompanies('','',true);
    } else {
      setCompanies(allActiveCompanies)
      setLoaded(true)
    }
  }
  const searchCompanies = async (state, service,getAll) => {
    setLoaded(false)
    setError({error: false, message: ''})
    let queryParams = params;
    if (state || service) {
      queryParams = { state: state || "", service: service || "" };
    }

    let results = await axios.get("/api/companies/search", { params: queryParams });
    results = results?.data || []
    console.log('results', results)
    if(results.length < 1){
      getAllCompanies()
      setError({error: true, message: "Sorry no contractors matched your search. Here's the complete list of companies."})
      return;
    }
    if(getAll){
      console.log('Getting all companies.')
      setAllActiveCompanies(results)
    }
    setCompanies(results);
    setLoaded(true)

    return;
  };
// console.log('companies', companies, 'error', error)
  return (
    <Container className="companies-page-container mb-2 ">
       <Helmet>
        <title>Contractors - Bridge Core</title>
          <link rel="canonical" href="https://bridgecoreutah.com/companies" />
        </Helmet> 
      <PageHeader
        pageTitle="BRIDGE CORE MEMBER CONTRACTORS"
        pageSubtitle="Search for contractors that work with us."
      />

      <Container className="companies-list-container rounded-3 p-3">
        <Row>
          <Col xs="12" md="8" className="mx-auto mb-4">
            <SearchFilter
              service={query.get('service')}
              state={query.get('state')}
              
            />
          </Col>
          {error.error && (
            <Col xs="8" className="mx-auto">
              <Alert
                variant="danger"
                onClose={() => setError({ ...error, error: false })}
                dismissible
              >
                {error.message}
              </Alert>
            </Col>
          )}
        </Row>
        {/* <Row>
          <Col xs="10" md="6" className="mx-auto">
            <Form>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="search"
                  // onChange={(e) => setSearch(e.target.value)}
                  placeholder="Type here to search by name."
                />
              </Form.Group>
            </Form>
          </Col>
        </Row> */}
        <Row className="p-3" id="list">
          <Col>
            {loaded && companies.length > 0 ? (
              companies
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => {
                  return <CompCard key={c.company_id} comp={c} />;
                })
            ) : (
              <Container className="d-flex justify-content-center">
                <Spinner animation="grow" />
              </Container>
            )}
            
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
// let companies = useCompanies();
// const [filteredCompanies, setFilteredCompanies] = useState([]);
// let searchFilter = {
//   state: props.match.params.state,
//   service: props.match.params.service,
// };
// const [error, setError] = useState({ error: false, message: "" });
// const [search, setSearch] = useState("");
// let companyList;
// let companyServices;
// if (companies.currentCompanies.companies) {
//   companyList = companies.currentCompanies.companies.filter(
//     (c) => c.active === true
//   );
//   companyServices = companies.currentCompanies.compServices;
// }
// const location = useLocation();
// //Scrolls the user to the list of companines
// useEffect(() => {
//   if (location.hash) {
//     let elem = document.getElementById(location.hash.slice(1));
//     if (elem) {
//       elem.scrollIntoView({ behavior: "smooth" });
//     }
//   } else {
//     window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
//   }
// }, [location]);
// let firstRender = useRef(true);

// useEffect(() => {
//   if (firstRender.current) {
//     firstRender.current = false;
//   } else {
//     if (filteredCompanies.length < 1) {
//       setError({
//         ...error,
//         error: true,
//         message: `Sorry no companies matched your search. Here's the complete list of companies.`,
//       });
//     } else {
//       setError({ ...error, error: false });
//     }
//   }
//   // eslint-disable-next-line
// }, [filteredCompanies]);
// useEffect(() => {
//   if (companyList && companyServices) {
//     filterCompanies();
//   }
//   // eslint-disable-next-line
// }, [props.history.location.pathname]);
// useEffect(() => {
//   if (companies.currentCompanies.companies) {
//     searchCompanies();
//   }
//   // eslint-disable-next-line
// }, [search.length]);

// const filterCompanies = () => {
//   let { state, service } = props.match.params;
//   console.log('state', state)
//   if (state && service) {
//     let filteredServices = companyServices.filter((c) => {
//       return c.service_name === service;
//     });
//     let getCompByService = companyList.filter((c) => {
//       if (
//         filteredServices.find(
//           (f) => parseInt(f.company_id) === parseInt(c.company_id)
//         )
//       ) {
//         return true;
//       }
//       return false;
//     });
//     let filtered = getCompByService.filter((c) => c.state === state);
//     setFilteredCompanies(filtered);
//   } else if (state && companyList) {
//     let filtered = companyList.filter((c) => c.state === state && c.active);
//     setFilteredCompanies(filtered);
//   } else if (service && companyServices) {
//     let filtered = companyServices.filter((c) => {
//       return c.service_name === service;
//     });
//     let getCompByService = companyList.filter((c) => {
//       if (
//         filtered.find(
//           (f) => parseInt(f.company_id) === parseInt(c.company_id)
//         )
//       ) {
//         return true;
//       }
//       return false;
//     });
//     setFilteredCompanies(getCompByService);
//   } else {
//     setFilteredCompanies(companyList);
//   }
// };
// const searchCompanies = () => {
//   let query = search.toLowerCase();

//   let results = companyList.filter((fc) => {
//     if (fc.name.toLowerCase().includes(query)) {
//       return true;
//     } else {
//       return false;
//     }
//   });
//   setFilteredCompanies(results);
// };
export default withRouter(Companies);
