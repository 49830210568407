import React from "react";
import "./EscrowServices.scss";
import Container from "react-bootstrap/Container";
import EntityCard from "./EntityCard/EntityCard";
import PageHeader from "../PageHeader/PageHeader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function EscrowServices() {
  const entities = [
    {
      imgUrl: "/images/blue-logos/customer.jpg",
      title: "PROJECT OWNER",
      desc: `When project owners work with contractors, they want to know that the project is being completed to a high standard and on schedule. Our project pathway approval system ensures your expectations are met by providing you piece of mind. `,
      desc2: `You are protected through our system because payments are only made once you and your contractor approve completed work that meets your standards. This means you do not pay for work up front that doesn’t get completed, and you do not pay for work is does not meet your standards.   `,
      desc3: `Should your contractor not complete work or not respond to requests, we will serve as an intermediary between parties. If that does not work after a predetermined time frame, then your money will be refunded. You only pay for work that was completed.`,
    },
    {
      imgUrl: "/images/BC-logo.png",
      title: "BRIDGE CORE",
      desc: `Bridge Core holds funds in a secure escrow account and pays contractors through the pathway approval system once predetermined project milestones are reached. Project funds and project status can be viewed anytime on our website by logging in. `,
      desc2: `
      After we receive payment, we let the contractor know that the project has been funded. We will hold the money until the project has been completed according to the specifications that were agreed upon at the time of payment through our pathway approval system. `,
      desc3: `Throughout the entire process, we will make sure that both the project owner and contractor have current and accurate information regarding the status and timeline of the project, and the state of their money.
      `,
    },
    {
      imgUrl: "/images/blue-logos/company.jpg",
      title: "CONTRACTOR",
      desc: `Once project owners and contractors check that project completion milestones are met in the pathway approval system, contractors will immediately get paid from the Bridge Core escrow account holding project funds. `,
      desc2: `Obtaining these payments throughout the project allows contractors to have fewer accounts receivable, so they can grow their business and fund new projects without waiting on payments.`,
      desc3: `Should you experience issues following the completion of work, we will serve as an intermediary between parties.  `,
    },
  ];
  return (
    <Container>
       <Helmet>
        <title>Escrow Services - Bridge Core</title>
          <link rel="canonical" href="https://bridgecoreutah.com/escrow" />
        </Helmet> 
      <PageHeader
        pageTitle="OUR ESCROW SERVICES"
        pageSubtitle="Your money handled well. Guaranteed."
      />
      <Container className="p-4">
        <h1 className="text-primary text-center" id="escrow-explanation-header">
          HOW OUR ESCROW SERVICES WORK
        </h1>
        <p>
          <b>
            The Bridge Core system is designed and built to support project
            owners and contractors.
          </b>
          <ol>
            <li>
              Project owner and contractor define project completion milestones
              in the Bridge Core system.{" "}
            </li>
            <li>
              Project owner deposits funds for the project in Bridge Core’s
              secure escrow account to be held for the duration of the project.{" "}
            </li>
            <li>
              Bridge Core pays the contractor(s) as defined project milestones
              are completed through the pathway approval system.{" "}
            </li>
          </ol>
         
        </p>
      </Container>
      <EntityCard
        key={entities[0].title}
        imgUrl={entities[0].imgUrl}
        title={entities[0].title}
        desc={entities[0].desc}
        desc2={entities[0].desc2}
        desc3={entities[0].desc3}
      />
      <Container className="text-center">
        <i className=" text-secondary bi bi-caret-down-fill escrow-arrows"></i>
      </Container>
      <EntityCard
        key={entities[1].title}
        imgUrl={entities[1].imgUrl}
        title={entities[1].title}
        desc={entities[1].desc}
        desc2={entities[1].desc2}
        desc3={entities[1].desc3}
      />
      <Container className="text-center">
        <i className=" text-secondary bi bi-caret-down-fill escrow-arrows"></i>
      </Container>

      <EntityCard
        key={entities[2].title}
        imgUrl={entities[2].imgUrl}
        title={entities[2].title}
        desc={entities[2].desc}
        desc2={entities[2].desc2}
        desc3={entities[2].desc3}
      />

      <Container className="p-4">
        <h1 className="text-primary text-center" id="escrow-explanation-header">
          WHAT HAPPENS WHEN THERE ARE DISAGREEMENTS?
        </h1>
        <p className="text-center">
          Our system is designed to create a collaborative and defined process
          for project owners and contractors to complete projects. From
          time-to-time, project owners and contractors do disagree about
          workmanship, timing, and other factors. We pride ourselves in
          facilitating a smooth process from the beginning of a project to the
          end.
        </p>
        <p className="text-center">
          If there are disagreements between the project owner and the
          contractor, a Bridge Core representative will join in a meeting and
          review the terms of the agreement. They will act as a third-party
          mediator and help the project owner and contractor come to an
          agreement.
        </p>
      </Container>
    </Container>
  );
}

export default EscrowServices;
