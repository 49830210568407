import React from "react";

function BBB() {
  return (
    <a
      href="https://www.bbb.org/us/ut/highland/profile/escrow-services/bridge-core-llc-1166-90042290/#sealclick"
      target="_blank"
      rel="nofollow"
    >
      <img
        src="https://seal-utah.bbb.org/seals/blue-seal-293-61-blacktxt-bbb-90042290.png"
        style={{ border: 0 }}
        alt="Bridge Core, LLC BBB Business Review"
      />
    </a>
  );
}

export default BBB;
