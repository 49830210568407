import React from "react";
import Intro from "./Intro/Intro";
import Promise from "./Promise/Promise";
import Join from "./Join/Join";
import Escrow from "./Escrow/Escrow";
import SearchFilter from "../SearchFilter/SearchFilter";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BBB from "../BBB";
import  Tooltip  from "react-bootstrap/Tooltip";
import  OverlayTrigger  from "react-bootstrap/OverlayTrigger";
import { Helmet } from "react-helmet";
function Landing() {
  return (
    <>
      <Helmet>
        <title>Welcome to Bridge Core</title>
          <link rel="canonical" href="https://bridgecoreutah.com/" />
        </Helmet> 
      <Intro />
      <Row>
        <Col className="d-flex justify-content-center align-items-center" xs="12">
          <BBB />
          <img src="/images/nmlslogo.jpg" alt="NMLS Logo" />
          <OverlayTrigger placement="bottom" overlay={<Tooltip placement="bottom" className="ml-2" title="NMLS ID: 2037655"><span className='d-block'>Utah - DFI Escrow Agency Registration.</span> <span className='d-block' >NMLS ID: 2484801</span></Tooltip>}>

          <i className="ml-2 bi bi-question-circle"></i>
          </OverlayTrigger>
        </Col>
      </Row>
      <Promise />
      <Escrow />
      <Join />
      <Row className="d-flex justify-content-center p-03">
        <Col xs="12" md="6" className="">
          <SearchFilter />
        </Col>
      </Row>
    </>
  );
}

export default Landing;
