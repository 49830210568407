import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import PageHeader from "../PageHeader/PageHeader";
import "./Pricing.scss";
import { Helmet } from "react-helmet";
function Pricing() {
  return (
    <Container>
       <Helmet>
        <title>Pricing - Bridge Core</title>
          <link rel="canonical" href="https://bridgecoreutah.com/pricing" />
        </Helmet> 
      <PageHeader
        pageTitle="PRICING"
        pageSubtitle="Always know what you'll pay."
      />
      <Row>
        <Col xs="12" md="6" lg="4">
          <div className=" customer-pricing rounded-3 p-4 h-100 price-card">
            <div className="d-flex justify-content-between align-items-start">
              <h5 className="text-center m-0 p-0">
                <b>Project Owners</b>
              </h5>
              <div className="d-flex flex-column">
                <h6 className="p-0 m-0">2.25%</h6>
                <p id="small-text">of Project Cost</p>
              </div>
            </div>
            <div>
              <ul>
                <li>
                  We securely hold your money until you agree the project is
                  complete.
                </li>
                <li>Customer Support</li>
                <li>Mediation between parties.</li>
              </ul>
            </div>
          </div>
        </Col>

        <Col xs="12" md="6" lg="4" className="">
          <div className=" subcontractor-pricing rounded-3 p-4 h-100 price-card">
            <div className="d-flex justify-content-between align-items-start">
              <h5 className="text-center m-0 p-0">
                <b>Subcontractors</b>
              </h5>{" "}
              <div className="d-flex flex-column">
                <h6 className="p-0 m-0">$150</h6>
                <p id="small-text">monthly</p>
              </div>
            </div>
            <div>
              <ul>
                <li>Free trial (call 801-830-3353) </li>
                <li>Listed as a Member Contractor.</li>
                <li>Contractor profile for users to contact you.</li>
                <li>Immediate payment upon project completion.</li>
                <li>Reduce Accounts Receivable</li>
                <li>Track project progress.</li>
                <li>Mediation between parties.</li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xs="12" md="6" lg="4">
          <div className=" general-contractor-pricing rounded-3  p-4 price-card">
            <div className="d-flex justify-content-between align-items-start">
              <h5 className="text-center m-0 p-0">
                <b>General Contractors</b>
              </h5>{" "}
              <div className="d-flex flex-column">
                <h6 className="p-0 m-0">$250</h6>
                <p id="small-text">monthly</p>
              </div>
            </div>
            <div>
              <ul>
                <li>Free trial (call 801-830-3353) </li>
                <li>Listed as a Member Contractor.</li>
                <li>Contractor profile for users to contact you.</li>
                <li>Immediate payment upon project completion.</li>
                <li>Reduce Accounts Receivable.</li>
                <li>Track project progress.</li>
                <li>See payments and manage subcontractors.</li>
                <li>Mediation between parties.</li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Pricing;
