import "./App.scss";
import React, { useEffect } from "react";
import routes from "./routes";
import Container from "react-bootstrap/Container";
import Header from "./Components/Public/Header/Header";
import Footer from "./Components/Public/Footer/Footer";
// import UserProvider, { useUser } from "./UserContext";
// import CompaniesProvider from "./CompaniesContext";
import { noNumberScroll } from "./assets/commonFunctions";
import { useHistory, useLocation } from "react-router";
import { useUser } from "./UserContext";
import ComingSoon from "./Components/ComingSoon";

function App() {
  noNumberScroll();
  let user = useUser();
  let location = useLocation();

  return (
    <Container fluid className="bg-light App p-0">
      {!location.pathname.includes("signed/") && <Header user={user} />}
      <main className="main-content">{routes}</main>
      {!location.pathname.includes("signed/") && <Footer user={user} />}

    </Container>
    // <ComingSoon />
  );
}

export default App;
