import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import axios from "axios";
import "../RequestForm/JobForm.scss";
import ListGroup from "react-bootstrap/ListGroup";
import SearchCompanyForm from "../../../../Company/SearchCompanyForm";
import FormControl from "react-bootstrap/FormControl";

import Swal from "sweetalert2";
function Primary({
  services,
  minimize,
  customerInitiated,
  createJob,
  primary,
  setPrimary,
  setCurrentSlide,
  setState,
  state,
  editMode,
  getJobTotal,
}) {
  const [companyResults, setCompanyResults] = useState([]);
  const [showCompanies, setShowCompanies] = useState(false);
  const [showSearch, setShowSearch] = useState(true);

  const [disabled, setDisabled] = useState(true);
  const [validated, setValidated] = useState(false);
  const statuses = ["Requested", "Approved", "Cancelled", "Complete"];
  const [submitting, setSubmitting] = useState(false);
  // console.log("Company states: ", {
  //   companyResults,
  //   showCompanies,
  //   primary,
  //   jobState: state,
  // });
  // useEffect(() => {
  //   axios.get("/api/job/status").then((res) => setStatuses(res.data));
  // }, []);
  useEffect(() => {
    validate();
  }, [
    primary.company_id,
    state.job_status,
    state.service,
    primary.amount_bc_owes,
    state.job_total,
  ]);
  const validate = () => {
    if (
      state.job_status !== "" &&
      state.service &&
      // primary.amt_bc_owes !== "" &&
      primary.company_id &&
      state.job_total !== ""
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    if (primary.name) {
      setShowSearch(false);
    }
  }, []);
  const next = () => {
    if (editMode) {
      axios.put("/api/job/basic", { state, primary }).then((res) => {
        setPrimary(res.data.primary);
        setState({ ...state, ...res.data.state });
        getJobTotal();
        console.log("res data", res.data);
      });
      setCurrentSlide(2);
    } else if (customerInitiated) {
      createJob();
      setSubmitting(true);
    } else {
      setCurrentSlide(2);
    }
  };
  return (
    <>
      {" "}
      <hr />
      <h3>General Contractor</h3>
      {showSearch && !editMode ? (
        <SearchCompanyForm
          setCompanyModal={setShowCompanies}
          setCompanyResults={setCompanyResults}
        />
      ) : (
        !editMode &&
        !minimize && (
          <p>
            <u
              onClick={() => {
                setShowSearch(true);
              }}
              className="pointer text-primary"
            >
              Search for a different general contractor.
            </u>
          </p>
        )
      )}
      <ListGroup>
        {showCompanies ? (
          <>
            <h5 className="text-center text-primary">
              Select a contractor from the list below:
            </h5>

            {companyResults &&
              companyResults
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => {
                  return (
                    <ListGroup.Item
                      key={c.company_id}
                      className="btn shadow"
                      onClick={() => {
                        setPrimary({
                          ...primary,
                          company_id: c.company_id,
                          name: c.name,
                          primary_company_name: c.name,
                        });

                        setShowCompanies(false);
                        setShowSearch(false);
                        Swal.fire({
                          title: "Contractor added.",
                          timer: 800,
                          showConfirmButton: false,
                        });
                      }}
                    >
                      <b>{c.name + " - ID:" + c.company_id}</b>
                    </ListGroup.Item>
                  );
                })}
          </>
        ) : null}
      </ListGroup>
      <Form.Control
        type="text"
        name="name"
        value={primary.name}
        disabled
        placeholder="Search for a contractor above and select it."
      />
      {!minimize && (
        <Button
          variant="primary"
          className="my-5"
          onClick={() => next()}
          disabled={!primary.company_id || submitting}
        >
          {submitting ? "Submitting..." : "Next"}
        </Button>
      )}
    </>
  );
}
export default Primary;
