import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import MilestoneDisbursement from "./MilestoneDisbursement";
import { dollarFormat } from "../../../../assets/commonFunctions";
function MilestoneDisbursements({ milestone }) {
  const [loaded, setLoaded] = useState(false);
  const [disbursements, setDisbursements] = useState([])
  const [payees, setPayees] = useState([])
  const loadData = async () => {
    if(loaded){
        setLoaded(false)
    }
     let disbResponse = await axios.get("/api/milestone/" + milestone?.m_id + "/disbursements")
     disbResponse = disbResponse?.data || []
    let payeeResponse = await axios
    .get("/api/milestone/" + milestone.m_id + "/payees")
    .catch((err) => console.log("err", err));
    //take any of the payees that have a disbursement and add the disbursement to the payee object. check this with mp_id in payee array and md_id in disb array. 
    // let payeesInfo = [...]
    payeeResponse = payeeResponse?.data || []
    payeeResponse = payeeResponse.map(payee => {
      console.log('disbursements', disbResponse)
        let disbursement = disbResponse.find(disb => disb.mp_id === payee.mp_id)
        if(disbursement){
            payee.disbursement = disbursement
        }
        return payee
    })
    console.log('payeeResponse', payeeResponse)
        setDisbursements(payeeResponse)
        // setPayees(payeeResponse?.data || [])
        setLoaded(true)
  
  };
 
  useEffect(() => {
    loadData()
  }, [milestone?.m_id]);
  return loaded ? (
    <>
      <h3>Disbursements</h3>
      {/* <h4>{dollarFormat(milestone.total_disbursed)}/{dollarFormat(milestone.total_to_disburse)}</h4> */}
      <div className='left-border-primary'>

      <Row className=" ">
          <Col xs="6"><b><u>Contractor</u></b></Col>
          <Col className="p-0"  xs="3">
            <b><u>Planned</u></b>
          </Col>
          <Col xs='3'><b><u>Paid</u></b></Col>
        </Row>
      {loaded ? (
        disbursements?.length > 0 ? (
          disbursements.map((disbursement) => {
            return <MilestoneDisbursement key={disbursement.md_id} payee={disbursement} />;
          })
        ) : (
          <em>No disbursements have been sent yet. Be sure the milestone is marked complete by both parties.</em>
        )

      ) : (
        <Container className=" d-flex justify-content-center">
          <Spinner animation="grow" />
        </Container>
      )}
      <Row className=" mb-5 ">
          <Col xs="6">Total</Col>
          <Col className="p-0"  xs="3">
            <b style={{ borderTop: "1px solid black" }}>
            {dollarFormat(milestone.total_disbursements_planned)}
            </b>
          </Col>
          <Col className="p-0"  xs="3">
            <b className='text-success' style={{ borderTop: "1px solid black" }}>
            {dollarFormat(milestone.total_disbursed)}
            </b>
          </Col>
        </Row>
      </div>
        </>
  ) : (
    <Container className=" d-flex justify-content-center">
                <Spinner animation="grow" />
              </Container>
  )
}

export default MilestoneDisbursements;
