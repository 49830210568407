import React from "react";
import "./Promise.scss";
import Sponsored from "./Sponsored/Sponsored";
import Statement from "./Statement/Statement";
import Container from "react-bootstrap/Container";
import PageHeader from "../PageHeader/PageHeader";
import {Helmet} from 'react-helmet'
function Promise() {
  return (
    <Container>
       <Helmet>
        <title>Our Promise - Bridge Core</title>
          <link rel="canonical" href="https://bridgecoreutah.com/promise" />
        </Helmet> 
      <PageHeader
        pageTitle="OUR PROMISE"
        pageSubtitle="We promise to do everything we can to help your projects go smoothly."
      />
      <Sponsored />
      <Statement />
    </Container>
  );
}

export default Promise;
