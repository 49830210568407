import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import {
  capitalizeFirstLetter,
  dollarFormat,
} from "../../../../assets/commonFunctions";
function MilestoneDisbursement({ payee }) {
  let { disbursement } = payee;
  return (
  
    <Row className="text-start">
      <Col xs="6">
        <b>{disbursement?.name}</b>
      </Col>
  
      <Col className="p-0" xs="3">
        {dollarFormat(payee.amount)}
      </Col>
      <Col xs="3" className="text-success p-0">
        {dollarFormat(disbursement?.amount)|| '$0.00'}
      </Col>
    </Row>
  );
}

export default MilestoneDisbursement;
