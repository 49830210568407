import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { capitalizeFirstLetter, dollarFormat } from "../../../../assets/commonFunctions";
function MilestoneDeposit({ deposit }) {
  return (
    <ListGroup.Item className='rounded-3 bg-very-light-grey p-2'>
      <Row>
        <Col xs="12">
          <h5 className='text-success'>{dollarFormat(deposit.amount)}</h5>
        </Col>
        <Col>
          <span className="">
            Submitted: {new Date(deposit.created).toLocaleDateString()}
          </span>
        </Col>
        <Col>
        <span>
            Status: <b>{capitalizeFirstLetter(deposit.payment_status)}{deposit.deposit_complete && ' ✅'}</b> 
        </span>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default MilestoneDeposit;
