import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useEffect } from "react";
import Checkbox from "../../../UIComponents/Checkbox";
import PayeeForm from "./PayeeForm";
import Swal from "sweetalert2";
import { dollarFormat } from "../../../../assets/commonFunctions";
import HintQuestionMark from "../../../UIComponents/HintQuestionMark";
function MilestoneForm({
  singleMode,
  editMode,
  milestone,
  jobId,
  afterSubmit,
}) {
  // const [includeFee, setIncludeFee] = useState(false);
  // const handleIncludeFee = () => {
  //   setIncludeFee(!includeFee);
  // }
  const [state, setState] = useState({
    title: "",
    definition: "",
    payees: [],
    totalFunds: 0,
    // todo: make position input when there are multiple milestones.
    position: 1,
  });
  const [submitting, setSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [jobData, setJobData] = useState({ job_id: 0, subs: [] });

  const loadData = () => {
    if (loaded) {
      setLoaded(false);
    }

    axios.get("/api/milestones/form/job/" + jobId).then((res) => {
      setJobData(res.data);
      // let tempPayees = [...res.data]
      if (singleMode) {
        setState({
          ...state,
          payees: [{ ...res.data.primary }, ...res.data.subs],
        });
      } else {
        if (res.data?.subs?.length < 1) {
          setState({ ...state, payees: [{ ...res.data.primary }] });
        }
      }

      setLoaded(true);
    });
  };
  const loadPayees = () => {
    axios
      .get("/api/milestone/" + milestone.m_id + "/payees")
      .then((res) => {
        loadData();

        setState({ ...state, ...milestone, payees: res.data });
      })
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    if (jobId > 0) {
      if (editMode) {
        loadPayees();
      } else {
        loadData();
      }
    }
  }, [jobId, editMode]);
  useEffect(() => {
    if (state.payees?.length > 0) {
      console.log("state.payees", state.payees);
      let sum = state.payees.reduce((acc, currentPayee) => {
        let { amount, feeIncluded } = currentPayee;
        amount = parseFloat(amount);
        if (feeIncluded) {
          //! use actual db value for fee percentage in future.
          amount = amount * 0.9775;
        }

        return (parseFloat(acc) || 0) + (amount || 0);
      }, 0);
      let includedFees = state.payees.reduce((acc, currentPayee) => {
        let { amount, includeFee } = currentPayee;
        amount = parseFloat(amount);
        if (includeFee) {
          //! use actual db value for fee percentage in future.
          amount = amount * 0.0225;
        } else {
          return acc;
        }

        return (parseFloat(acc) || 0) + (amount || 0);
      }, 0);
      let feeAmount = sum * 0.0225;
      let customerFeePortion = parseFloat(feeAmount) - parseFloat(includedFees);
      let total = sum +customerFeePortion;
      let payeeTotal = sum - includedFees;
      // if(!includeFee){
        // total = ;
      // } else {

      //   payeeTotal -= feeAmount;
      // }

      setState({
        ...state,
        totalFunds: total,
        feeAmount,
        payeeTotal: payeeTotal,
      });
    } else {
      setState({ ...state, totalFunds: 0 });
    }
  }, [state.payees]);
  const [validated, setValidated] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setSubmitting(true);
      let simplePayees = state.payees?.map((p) => {
        return {
          jc_id: p.jc_id,
          percentage: p.percentage,
          amount: p.amount,
          type: p.type,
          includeFee: p.includeFee,
        };
      });
      let body = {
        job_id: jobData.job_id,
        title: state.title,
        definition: state.definition,
        position: state.position,
        payees: simplePayees,
        original_id: milestone?.m_id,
      };
      axios
        .post("/api/milestone", body)
        .then((res) => {
          if (!singleMode) {
            Swal.fire({
              icon: "success",
              title: "Milestone Created Successfully.",
              timer: 2000,
              showConfirmButton: false,
            });
          }
          if (afterSubmit) {
            afterSubmit();
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Error adding milestone.",
            text: "Please try again.",
            timer: 6000,
          });
        });
    }
  };
  const handleInput = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handlePayeeInput = (payee) => {
    let newPayees = [...state.payees];
    if (state.payees.some((p) => p.company_id === payee.company_id)) {
      newPayees = newPayees.filter((p) => p.company_id !== payee.company_id);
    } else {
      newPayees = [...newPayees, payee];
    }
    setState({ ...state, payees: newPayees });
  };
  return loaded ? (
    <Form
      className=""
      noValidate
      disabled={submitting}
      validated={validated}
      onSubmit={handleSubmit}
    >
      {!singleMode ? (
        <>
          <h3 className="mt-3">New Milestone</h3>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={state.title}
              onChange={handleInput}
              required
              placeholder="Title of the Milestone"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Define the Milestone Criteria</Form.Label>
            <Form.Control
              as="textarea"
              rows="4"
              name="definition"
              value={state.definition}
              onChange={handleInput}
              placeholder="Enter the what needs to happen for this milestone to be considered complete. Be as specific as possible."
              required
            />
          </Form.Group>
        </>
      ) : (
        <Form.Group className="my-3">
          <Form.Label>Define the Completion Criteria</Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            name="definition"
            value={state.definition}
            onChange={handleInput}
            placeholder="Enter the what needs to happen for this project to be considered complete. Please be as specific as possible."
            required
          />
        </Form.Group>
      )}
      <hr />
      <h3 className="mt-3">Funding Disbursements</h3>
      <Form.Group>
        <Form.Label>
          Which contractors will be paid after this milestone is complete?
        </Form.Label>

        <Checkbox
          disabled={singleMode || jobData?.subs?.length < 1}
          handleClick={() => handlePayeeInput(jobData.primary)}
          label={jobData.primary.name}
          checked={state.payees.some(
            (p) => p.company_id === jobData.primary.company_id
          )}
        />
        {jobData?.subs?.map((sub) => {
          return (
            <Checkbox
              disabled={singleMode}
              key={sub.company_id}
              handleClick={() => handlePayeeInput(sub)}
              label={sub.name}
              checked={state.payees.some(
                (p) => p.company_id === sub.company_id
              )}
            />
          );
        })}
      </Form.Group>
      {state.payees.map((payee, index) => {
        return (
          <PayeeForm
            singleMode={singleMode}
            key={payee.jc_id}
            payee={payee}
            parentState={state}
            validated={validated}
            setParentState={setState}
            index={index}
            //! use database value for fee percentage
            feePercentage={0.0225}
          />
        );
      })}
      <h5 className="mt-3">
        Total Funds to Disburse at Milestone Completion:{" "}
        <em className="text-success">{dollarFormat(state.payeeTotal)}</em>
      </h5>
      <h5>
        Project Fee (2.25%): {"  "}
        <em className="text-success">{dollarFormat(state.feeAmount)}</em>
      </h5>

      <h5>
        Customer Deposit Total: {"  "}
        <em className="text-success">{dollarFormat(state.totalFunds)}</em>
      </h5>
      <Button disabled={submitting} className="my-4 float-end" type="submit">
        {submitting ? "Submitting..." : "Submit"}
      </Button>
    </Form>
  ) : (
    <Container className="main-content d-flex justify-content-center">
      <Spinner animation="grow" />
    </Container>
  );
}

export default MilestoneForm;
