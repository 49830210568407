import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

function HintQuestionMark({ hintJSX }) {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip placement="bottom" className="">
          {hintJSX}{" "}
        </Tooltip>
      }
    >

      <i className="ms-2 bi bi-question-circle"></i>
    </OverlayTrigger>
  );
}

export default HintQuestionMark;
