import React, { useState, useEffect, Suspense } from "react";
// import {
//   getAuth,
//   signOut,
// } from "firebase/auth";
import "./Header.scss";
import Login from "../Login/Login";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../../UserContext";
import { withRouter } from "react-router-dom";
import axios from "axios";
const Logout = React.lazy(() => import("./Logout"));
function Header({ user, history, location }) {
  // let auth = getAuth();
  const [loginModal, setLoginModal] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // const logout = () => {
  //   signOut(auth)
  //     .then(() => console.log("Logged out of firebase."))
  //     .catch((err) => console.log(err));
  //   axios.post("/api/auth/logout").then(() => {
  //     setLoggedIn(false);
  //   });
  //   user.setCurrentUser({});
  //   history.push("/");
  // };
  // useEffect(() => {
  //   if (user && user.currentUser) {
  //     axios("/api/user")
  //       .then(async (res) => {
  //         onAuthStateChanged(auth, async (user) => {
  //           if (!user) {
  //             await signInWithCustomToken(auth, res.data.token)
  //               .then((userCred) => {
  //                 console.log("Logged in to Firebase.");
  //               })
  //               .catch((err) => console.log(err));
  //           } else {
  //             console.log("Firebase user still logged in.");
  //           }
  //         });
  //         user.setCurrentUser(res.data);
  //         setLoggedIn(true);
  //       })
  //       .catch((err) => {
  //         console.log(err.response.data);
  //         setLoggedIn(false);
  //       });
  //   }

  // }, []);
  // console.log('Header User: ', user?.currentUser?.permissions)
  useEffect(() => {
    user?.currentUser?.user_id && user?.currentUser?.user_id > 0
      ? setLoggedIn(true)
      : setLoggedIn(false);
  }, [user?.currentUser, location?.pathname]);

  const goToPortal = () => {
    if (user?.currentUser?.user_id) {
      if (user.currentUser.role === "admin") {
        history.push("/admin");
      } else if (!user.currentUser.role) {
        history.push("/portal");
      } else {
        // todo: once there are more roles, you can route them here.
        history.push("/login");
      }
    }
  };

  return (
    <Navbar bg="light" expanded={expanded} expand="lg">
      <Container>
        <Link className="header-links " to="/">
          <Navbar.Brand id="brand">
            <img
              className="d-inline-block align-middle mr-3"
              id="logo-image"
              src="/images/BC-logo-fav.png"
              alt="bridge core logo"
            />
            {"  "}
            <div className=" d-inline-block align-middle ">
              <span id="bridge-text">BRIDGE</span>
              <span id="core-text"> CORE</span>
              <span className="text-secondary ms-1">
                <em></em>
              </span>
            </div>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto text-primary ">
            <div className="nav-link" onClick={() => setExpanded(false)}>
              <Link className="header-links float-end" to="/promise">
                <span>Our Promise</span>
              </Link>
            </div>

            <div className="nav-link" onClick={() => setExpanded(false)}>
              <Link className="header-links float-end float-end" to="/escrow">
                <span>Escrow Services</span>
              </Link>
            </div>
            <div className="nav-link" onClick={() => setExpanded(false)}>
              <Link className="header-links float-end" to="/join">
                <span>Join Us</span>
              </Link>
            </div>
            <div className="nav-link" onClick={() => setExpanded(false)}>
              <Link className="header-links float-end" to="/companies">
                <span>Find a Contractor</span>
              </Link>
            </div>
            <div className="nav-link" onClick={() => setExpanded(false)}>
              <Link className="header-links float-end" to="/pricing">
                <span>Pricing</span>
              </Link>
            </div>
            <div className="nav-link" onClick={() => setExpanded(false)}>
              <Link className="header-links float-end" to="/contact">
                <span>Contact Us</span>
              </Link>
            </div>

            {loggedIn ? (
              <>
                <div className="nav-link" onClick={() => setExpanded(false)}>
                  <div
                    className="header-links float-end text-primary "
                    onClick={() => goToPortal()}
                  >
                    <span>Account</span>
                  </div>
                </div>
                <div className="nav-link" onClick={() => setExpanded(false)}>
                  <Suspense fallback={<div></div>}>
                    <Logout user={user} setLoggedIn={setLoggedIn} />
                  </Suspense>
                </div>
              </>
            ) : (
              <div className="nav-link">
                <Link
                  className="header-links float-end"
                  to={location.pathname}
                  onClick={() => setLoginModal(!loginModal)}
                >
                  <span>Login</span>
                </Link>
              </div>
            )}
            <Modal
              show={loginModal}
              onHide={() => setLoginModal(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Login
                setExpanded={setExpanded}
                setLoggedIn={setLoggedIn}
                toggleModal={setLoginModal}
              />
            </Modal>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default withRouter(Header);
