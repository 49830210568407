import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import { phoneFormat } from "../../../../../assets/commonFunctions";
import Swal from "sweetalert2";
import SearchCustomerPhone from "../../../../Customer/SearchCustomerPhone";

function Customer({
  primary,
  setState,
  state,
  setNewJobID,
  setCurrentSlide,
  setPrimary,
  disabled,
    customerInitiated,
  user,
}) {
  const [customerResults, setCustomerResults] = useState([]);
  const [showCustomers, setShowCustomers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });
  const [newCustomer, setNewCustomer] = useState({
    new: false,
    customer: {
      first_name: "",
      last_name: "",
      email: "",
      customer_id: 0,
    },
    primary_name: "",
  });
    useEffect(() => {
    if (customerInitiated) {
      setCustomer({
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        email: user.email,
        user_id: user.user_id,
      });
    }
  }, [customerInitiated]);
  console.log('customerinitiated', customerInitiated)
  // const [newCustomer, setNewCustomer] = useState()
  const [disabledForm, setDisabledForm] = useState(true);
  const [validForm, setValidForm] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  // console.log("Customer states : ", {
  //   customerResults,
  //   showCustomers,
  //   loading,
  //   disabled,
  //   primary,
  // });
  useEffect(() => {
    console.log('checking form valid')
    if (
      customer?.first_name &&
      customer?.last_name &&
      customer?.email && 
      customer?.phone.length > 13  ) {
      setValidForm(true);
    } else if (validForm) {
      setValidForm(false);
    }
  }, [customer]);
  useEffect(() => {
    if (customer.first_name && customer.last_name) {
      setCustomer({
        ...customer,
        customer_name: customer.first_name + " " + customer.last_name,
      });
    }
  }, [customer.first_name, customer.last_name]);
  const submitCustomer = (e) => {
    e.preventDefault();
    setLoading(true);
if(!validForm){
  setLoading(false)
  return
}
    // const addJob = (customerInfo) => {
    //   let body = state;
    //   //Customerinfo is only for if a new customer was added to the db prior to creating the job.
    //   if (customerInfo) {
    //     body = {
    //       ...state,
    //       customer_id: customerInfo.customer_id,
    //       customer_name: customerInfo.customer_name,
    //     };
    //   }
    //   console.log("body", body);
    //   axios
    //     .post("/api/job/basic", body)
    //     .then((res) => {
    //       let job_id = res.data.job_id;
    //       axios
    //         .post("/api/job/primary", {
    //           ...primary,
    //           job_id: job_id,
    //           amt_bc_owes: primary.amt_bc_owes,
    //         })
    //         .then((res) => {
    //           setState({
    //             ...state,
    //             job_id,
    //             ...customer,
    //             customer_id: body.customer_id,
    //           });
    //           setPrimary({ ...primary, jc_id: res.data.jc_id, job_id });
    //           setLoading(false);
    //           setNewJobID(job_id);
    //           if (customerInfo.alreadyExisted) {
    //             setNewCustomer(false);
    //           }
    //           setCurrentSlide(3);
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //           setLoading(false);
    //         });
    //     })
    //     .catch((err) => {
    //       setLoading(false);
    //     });
    // };
    if (newCustomer.new) {
      //double checks the db for the same email, returns the customer info needed for the job. If no customer, creates one.
      axios.post("/api/job/customer/new", customer).then((res) => {
        let customerInfo = res.data;
        setState({ ...state, ...customerInfo,customer: {...customerInfo}, new_customer: true });
        setNewCustomer({
          new: false,
          customer: {
            first_name: customerInfo.first_name,
            last_name: customerInfo.last_name,
            email: customerInfo.email,
            customer_id: customerInfo.customer_id,
          },
          primary_name: primary?.primary_company_name,
        });
        // addJob(customerInfo);
      });
    } else {
      // addJob(false);
    }
  };
  console.log("newCustomer", newCustomer);
  useEffect(() => {
    validate();
  }, [state?.customer_id, state?.customer_name, customer]);

  const validate = () => {
    if (!newCustomer?.new && state?.customer_id > 0 && state?.customer_name) {
      setDisabledForm(false);
    } else if (
      newCustomer &&
      customer.first_name &&
      customer.last_name &&
      customer.email &&
      customer.phone.length > 13
    ) {
      setDisabledForm(false);
    } else {
      setDisabledForm(true);
    }
  };
  const handleInput = (e) => {
    if (e.target.name === "phone") {
      let format = phoneFormat(e.target.value);
      setCustomer({ ...customer, phone: format });
    } else {
      setCustomer({ ...customer, [e.target.name]: e.target.value });
    }
  };

  return disabled ? (
    <h4 className={customerInitiated ? "text-dark":"text-secondary"}>Customer {customerInitiated ? "(You)":". . ."} </h4>
  ) : (
    <>
      <h4>Customer</h4>
      <p>
        You may search for a customer by their phone number if they already
        have an account with Bridge Core.
      </p>
      <p>
        If they do not have an account, or you cannot find it with the search, select "New Customer" and provide
        some basic information and we will email them a link to sign up and be
        added to this project.
      </p>
      <Row className='my-2'>
        <Col>
          <Button
            className="btn-sm me-3 "
            variant="primary"
            onClick={() => {
              setShowSearch(!showSearch);
              if (!showSearch) {
                setNewCustomer({ ...newCustomer, new: false });
              }
            }}
          >
            {showSearch ? "Hide Search" : "Search Customer by Phone Number"}
          </Button>

          <Button
            className="btn-sm ms-1"
            variant="success"
            onClick={() => {
              if (!newCustomer?.new) {
                setShowSearch(false);
              }
              setNewCustomer({ ...newCustomer, new: !newCustomer.new });
            }}
          >
            {newCustomer?.new
              ? `Hide "New Customer" Form`
              : "Create New Customer"}
          </Button>
        </Col>
      </Row>
      {showSearch ? (
        <SearchCustomerPhone
          setCustomerModal={setShowCustomers}
          setCustomerResults={setCustomerResults}
        />
      ) : null}

      <ListGroup>
        {showSearch && customerResults.length > 0 ? (
          <>
            <h6 className="text-primary text-center">
              Select the customer from below.
            </h6>
            {showCustomers &&
              customerResults.map((c) => {
                return (
                  <ListGroup.Item
                    key={c.user_id}
                    className="btn"
                    onClick={() => {
                      setState({
                        ...state,
                        customer_id: c.user_id,
                        customer_name: c.first_name + " " + c.last_name,
                      });
                      setShowSearch(false);
                      setShowCustomers(false);
                      Swal.fire({
                        title: "Project Owner added.",
                        timer: 800,
                        showConfirmButton: false,
                      });
                    }}
                  >
                    {c.first_name +
                      " " +
                      c.last_name +
                      " - " +
                      phoneFormat(c.phone)}
                  </ListGroup.Item>
                );
              })}
          </>
        ) : state?.customer_name ? (
          <p
            onClick={() => setShowSearch(true)}
            className="text-primary pointer"
          >
            <u>Search with a different phone number.</u>
          </p>
        ) : null}
      </ListGroup>

      <Form noValidate onSubmit={submitCustomer}>
        {state?.customer_name && !newCustomer?.new ? (
          <Form.Group as={Col} xs="12" md="6">
            <Form.Label className="m-0">Customer Name</Form.Label>

            <Form.Control
              required
              value={state.customer_name}
              disabled
              type="text"
              placeholder="John Doe"
            />
          </Form.Group>
        ) : null}
        {newCustomer?.new ? (
          <>
            <h6>
              Adding a new customer will send them an email inviting them
              to complete their account setup.{" "}
            </h6>
            <Row>
              <Form.Group as={Col} xs="12" md="6">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="first_name"
                  value={customer.first_name}
                  onChange={handleInput}
                  placeholder="John"
                />
              </Form.Group>
              <Form.Group as={Col} xs="12" md="6">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  value={customer.last_name}
                  onChange={handleInput}
                  placeholder="Doe"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs="12" md="6">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={customer.email}
                  onChange={handleInput}
                  placeholder="johndoe@example.com"
                />
              </Form.Group>
              <Form.Group as={Col} xs="12" md="6">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={customer.phone}
                  onChange={handleInput}
                  placeholder="(111) 222 - 3333"
                />
              </Form.Group>
            </Row>
          </>
        ) : null}
        {/* <Button
          className="float-start my-3"
          onClick={() => setCurrentSlide(1)}
          variant="secondary"
          disabled={loading}
        >
          Back
        </Button>
      */}
        {(!state.customer_id || state?.customer_id < 1) && newCustomer?.new && (
          <Button
            className=" my-3"
            variant="primary"
            disabled={loading || disabled || !validForm}
            type="submit"
          >
            {loading ? "Loading..." : "Add to this Project"}
          </Button>
        )}
      </Form>
    </>
  );
}
export default Customer;
